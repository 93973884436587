import React from 'react';
import CardsReservationsByStatus from '../CardsReservationsByStatus';

interface ShowCheckinsProps {
  tabsName: string;
  getIncome: (() => void) | undefined;
  ref: (node?: Element | null | undefined) => void;
  startDate?: Date | null;
  endDate?: Date | null;
  isToday?: boolean;
  noLimit?: boolean;
}

const ShowCheckins = ({
  tabsName,
  getIncome,
  ref,
  endDate,
  startDate,
  isToday,
  noLimit,
}: ShowCheckinsProps) => {
  return (
    <>
      <CardsReservationsByStatus
        title="Suas reservas aguardando confirmação"
        tabsName={tabsName}
        status={['AWAITING_CONFIRMATION']}
        showButtonConfirm={false}
        getIncome={getIncome}
        innerRef={ref}
        endDate={endDate}
        startDate={startDate}
        isToday={isToday}
        noLimit={noLimit}
      />
      <CardsReservationsByStatus
        title="Suas reservas pendentes"
        tabsName={tabsName}
        status={['PENDING']}
        showButtonConfirm={false}
        getIncome={getIncome}
        innerRef={ref}
        endDate={endDate}
        startDate={startDate}
        isToday={isToday}
        noLimit={noLimit}
      />
      <CardsReservationsByStatus
        title="Suas reservas confirmadas"
        tabsName={tabsName}
        status={['CONFIRMED']}
        showButtonConfirm={false}
        getIncome={getIncome}
        innerRef={ref}
        endDate={endDate}
        startDate={startDate}
        isToday={isToday}
        noLimit={noLimit}
      />
      <CardsReservationsByStatus
        title="Suas reservas finalizadas"
        tabsName={tabsName}
        status={['DONE']}
        showButtonConfirm={false}
        getIncome={getIncome}
        innerRef={ref}
        endDate={endDate}
        startDate={startDate}
        isToday={isToday}
        noLimit={noLimit}
      />
      <CardsReservationsByStatus
        title="Suas reservas canceladas/expiradas"
        tabsName={tabsName}
        status={['CANCELED', 'EXPIRED']}
        showButtonConfirm={false}
        getIncome={getIncome}
        innerRef={ref}
        endDate={endDate}
        startDate={startDate}
        isToday={isToday}
        noLimit={noLimit}
      />
    </>
  );
};

export default ShowCheckins;
