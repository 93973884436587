import * as Sentry from '@sentry/nextjs';

import { clientGet } from '@/services/client';
import { apiEndpoints } from '@/config/endpoints';
import { GetCheckins, GetCheckinsProps } from '@/types/checkin';
import { endOfDay, startOfDay } from 'date-fns';

const defaultValue = {
  currentPage: 0,
  data: [],
  totalPages: 0,
  totalResults: 0,
};

function formatStatusList(statusArray: string[]): string {
  return statusArray
    .map(status => `statusList[]=${encodeURIComponent(status)}`)
    .join('&');
}

const getCheckins = async ({
  pageParam,
}: GetCheckinsProps): Promise<GetCheckins> => {
  const id = pageParam?.establishmentId;
  const page = pageParam?.page ?? 1;
  const status = pageParam?.status ?? '';
  const limit = pageParam?.noLimit ? '' : '&limit=50';
  const startDate = pageParam?.startDate
    ? startOfDay(new Date(pageParam?.startDate)).toISOString()
    : startOfDay(new Date()).toISOString();
  const endDate = pageParam?.endDate
    ? endOfDay(new Date(pageParam?.endDate)).toISOString()
    : endOfDay(new Date()).toISOString();

  if (!id) return defaultValue;
  try {
    const hasStatus = status && status[0]?.length > 0;

    const formatedStatus = hasStatus ? `&${formatStatusList(status)}` : '';
    const formatedDate =
      pageParam?.startDate || pageParam?.isToday
        ? `&startDate=${startDate}&endDate=${endDate}`
        : '';

    const response = await clientGet(
      `${apiEndpoints.getAllCheckins(
        id
      )}?page=${page}${limit}${formatedStatus}${formatedDate}`
    );

    const checkins: GetCheckins = response;

    return checkins;
  } catch (error) {
    Sentry.captureException(error);
    return defaultValue;
  }
};

export default getCheckins;
