import styled from 'styled-components';

interface ButtonProps {
  isAccept?: boolean;
}

export const CardContainer = styled.div`
  width: 212px;
  min-height: 255px;
  height: 100%;
  background-color: transparent;
  border-radius: 16px;
  min-width: 300px;
  padding: 21px 34px 31px 21px;
  position: relative;
  border: 1px solid #d1d1d1;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  .userName {
    position: relative;
    display: inline-block;
    font-size: 1.5em;
    color: #000000;
    width: fit-content;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -4px;
      height: 2px;
      background-color: #000000;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.4s ease-in-out;
    }
  }

  :hover {
    transform: translate(5px, -5px);
    background-color: #ebeaea;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);

    .userName::after {
      transform: scaleX(1);
    }
  }

  @media screen and (max-width: 1368px) {
    width: 100%;
    height: auto;
    min-height: auto;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 26px;
  color: #000;
  margin-bottom: 16px;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 28px;
  color: #000;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Activity = styled.div`
  font-size: 14px;
  font-family: 'Graphik', sans-serif;
  font-weight: 400;
  line-height: 15px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TimeInfo = styled.div`
  font-size: 14px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 25px;
  color: #000;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const Button = styled.div<ButtonProps>`
  width: 170px;
  padding: 7.16px 9.54px 7.16px 14.31px;
  border-radius: 8px;
  border: none;
  color: #505050;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  border-radius: 20px;
  font-size: 11px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 23px;
  border: 1px solid #d1d1d1;
  height: 33px;

  svg path {
    fill: #505050;
  }
`;

export const Icon = styled.span`
  font-size: 1.25rem;
`;
