import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pt } from 'date-fns/locale';
import { Tabs } from '@/components/common/Tabs';
import { Alert } from '@/components/common/Alert';
import { tabsItems } from '@/utils/tabsItems';

import { useListReservationsController } from './useListReservationsController';
import { ListReservationsProps } from './types';
import ShowCheckins from './components/ShowHomeCheckins';
import ShowNextCheckins from './components/ShowNextCheckins';
import {
  TabsContainer,
  TabsWrapper,
  ButtonContainer,
  FilterButton,
  ButtonText,
  DateRangeText,
  DatePickerContainer,
  ConfirmButton,
  ContentContainer,
} from './styles';

export const ListReservations: FC<ListReservationsProps> = ({
  isHome = false,
  getIncome,
}) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;
  const [showDatePicker, setShowDatePicker] = useState(false);

  const { tabsName, onChangeTabs, role, ref } = useListReservationsController();

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const showFilter = !isHome;

  return (
    <>
      <TabsContainer>
        <TabsWrapper>
          {tabsItems?.map(({ name, value }) => (
            <Tabs
              key={name}
              text={name}
              active={tabsName === value}
              onClick={() => onChangeTabs(value)}
            />
          ))}
        </TabsWrapper>
        {role !== 'OWNER' && <Alert width={350} />}
      </TabsContainer>

      <ButtonContainer>
        {showFilter && (
          <FilterButton onClick={toggleDatePicker}>
            <ButtonText>Filtrar por data</ButtonText>
          </FilterButton>
        )}
        {startDate && endDate && (
          <DateRangeText>
            {`De: ${startDate.toLocaleDateString()} Até: ${endDate.toLocaleDateString()}`}
          </DateRangeText>
        )}
      </ButtonContainer>

      {showDatePicker && (
        <DatePickerContainer>
          <DatePicker
            selected={startDate}
            onChange={update => setDateRange(update)}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            locale={pt}
          />
          <ConfirmButton onClick={toggleDatePicker}>Confirmar</ConfirmButton>
        </DatePickerContainer>
      )}

      <ContentContainer>
        <ShowCheckins
          isToday={isHome}
          startDate={startDate}
          endDate={endDate}
          tabsName={tabsName}
          ref={ref}
          getIncome={getIncome}
          noLimit={!isHome}
        />
      </ContentContainer>
    </>
  );
};

export default ListReservations;
