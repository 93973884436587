/* eslint-disable @typescript-eslint/ban-ts-comment */
import useStore from '@/store';

import getCheckins from '@/services/checkins/getCheckins';
import { useInfiniteQuery } from '@tanstack/react-query';
import { GetCheckins } from '@/types/checkin';
import { useEffect, useState } from 'react';
import { CardsReservationsData } from '@/components/CardsReservations/types';

export const useGetCheckins = (
  status: string[],
  tabsName: string,
  isToday?: boolean,
  startDate?: Date | null,
  endDate?: Date | null,
  noLimit?: boolean
) => {
  const { establishment } = useStore();
  const [checkins, setCheckins] = useState<CardsReservationsData[]>([]);

  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isRefetching,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'checkins',
      status,
      tabsName,
      isToday,
      startDate,
      endDate,
      noLimit,
    ],
    queryFn: getCheckins as any,
    initialPageParam: {
      page: 1,
      establishmentId: establishment?.id ?? '',
      status,
      isToday,
      startDate,
      endDate,
      noLimit,
    },
    getNextPageParam: (lastPage: GetCheckins | undefined) => {
      if (lastPage?.totalPages === lastPage?.currentPage) {
        return undefined;
      }

      const nextPage = lastPage?.currentPage
        ? lastPage.currentPage + 1
        : undefined;

      return {
        page: nextPage,
        establishmentId: establishment?.id ?? '',
        status,
        isToday,
        startDate,
        endDate,
        noLimit,
      };
    },
  });

  const filteredCheckins = (checkins: CardsReservationsData[] | []) => {
    if (tabsName.toLowerCase() !== 'all') {
      const filteredReservations = checkins?.filter(
        ({ status }: CardsReservationsData) =>
          status.toLowerCase() === tabsName.toLowerCase()
      );
      setCheckins(filteredReservations ?? []);
    } else {
      setCheckins(checkins);
    }
  };

  const flattenPages = async () => {
    const pages = data?.pages?.flatMap(page => page?.data);
    const filteredData = pages?.filter(checkins => checkins !== undefined);

    const formatedData = filteredData ? filteredData : [];

    //@ts-ignore
    filteredCheckins(formatedData);
  };

  useEffect(() => {
    if (isRefetching) return;

    flattenPages();
  }, [data, isRefetching, startDate, endDate]);

  const loading = isLoading || isFetchingNextPage || isRefetching;

  return {
    checkins,
    fetchNextPage,
    loading,
    refetch,
    hasNextPage,
  };
};

export default useGetCheckins;
