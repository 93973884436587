import styled from 'styled-components';

export const Container = styled.section`
  margin-bottom: 50px;

  div.slider-pagination {
    .swiper {
      height: 290px !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
      color: #434343 !important;
      top: 40% !important;
    }
    .swiper-pagination-bullet-active {
      background-color: #434343 !important;
    }
    .swiper-pagination-bullet {
      width: 15px !important;
      height: 15px !important;
    }
  }
`;

export const Title = styled.h2`
  font-family: 'Graphik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #434343;
  margin-bottom: 21px;
`;

export const Warning = styled.p`
  font-family: 'Graphik', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #838383;
`;

export const BoxCards = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: scroll;
  padding-right: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Button = styled.button`
  width: 500px;
  padding: 9px 12px 9px 18px;
  height: 42px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  color: '#fff';
  background-color: #e1ff2b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 13px;
  font-family: 'Graphik', sans-serif;
  font-weight: 500;
  line-height: 15px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  &:hover {
    opacity: 0.9;
  }
`;
